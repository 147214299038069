import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'



const about = () => {
    return (
        <div>
            <Layout>
                <Head title="About" />
                <p>Hey, Welcome to VID VEDA Blog, We're really excited to have you with us.</p>
            </Layout>
        </div>
    )
}

export default about
